import { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { Fieldset } from "primereact/fieldset"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Calendar } from "primereact/calendar"
import { Divider } from "primereact/divider"
import { InputNumber } from "primereact/inputnumber"
import moment from "moment"
import commonStyles from "../../common.module.css"
import api from "../../../api"
import userContext from "../../../context/userContext"
import { useTeams } from "../../../api/queries/useTeams"
import { useTaskTags } from "../../../api/queries/useTaskTags"
import backConfirmation from "../../../function/backConfirmation"
import { useOperations } from "../../../api/queries/useOperations"
import useParentUsers from "../../../hook/useParentUsers"
import FileList from "../../File/FileList"
import { useMachineTypes } from "../../../api/queries/useMachineTypes"
import { usePlaces } from "../../../api/queries/usePlaces"
import { useAllMachines } from "../../../api/queries/useAllMachines"
import { formatMachineName } from "../../../function/machine"
import { useTaskDefects } from "../../../api/queries/useTaskDefects"
import { useTaskUnits } from "../../../api/queries/useTaskUnits"
import { useTaskProcesses } from "../../../api/queries/useTaskProcesses"

export default function TaskReportForm() {
  const { uuid, managementUuid, parentUuid } = useParams()

  const back = () => window.history.back()
  const { user: userDetail, hasPerm, hasRole, isAdmin } = useContext(userContext)
  const nav = useHistory()

  const [state, setState] = useState("OPEN")
  const [name, setName] = useState("")
  const handleNameChange = (e) => setName(e.target.value)
  const [description, setDescription] = useState("")
  const handleDescriptionChange = (e) => setDescription(e.target.value)
  const [startsAt, setStartsAt] = useState(moment().set({ h: 0, m: 0, s: 0 })._d)
  const handleStartsAtChange = (e) => setStartsAt(e.target.value)
  const [endsAt, setEndsAt] = useState(moment().set({ h: 23, m: 59 })._d)
  const handleEndsAtChange = (e) => setEndsAt(e.target.value)

  // --- machine ---
  const { data: machines = [] } = useAllMachines()
  const { data: operations = [] } = useOperations()
  const { data: places = [] } = usePlaces()
  const { data: machineTypes = [] } = useMachineTypes()

  const [MFOperation, setMFOperation] = useState(userDetail.operation ? userDetail.operation.uuid : null)
  const handleMFOperationChange = (e) => {
    setMFOperation(e.target.value)
    setMachine(null)
    setMFPlace(null)
    setMFType(null)
  }
  const getMFOperationOptions = () => operations // operation is not filtered

  const [MFPlace, setMFPlace] = useState(null)
  const handleMFPlaceChange = (e) => {
    setMFPlace(e.target.value)
    setMachine(null)
    setMFType(null)
  }
  const getMFPlaceOptions = () => MFOperation
    ? places.filter((place) => place.operation.uuid === MFOperation)
    : places

  const [MFType, setMFType] = useState(null)
  const handleMFTypeChange = (e) => {
    setMFType(e.target.value)
    setMachine(null)
  }
  const getMFTypeOptions = () => {
    const _places = getMFPlaceOptions().map((place) => place.uuid)
    const _machines = MFPlace
      ? machines.filter((machine) => machine.place.uuid === MFPlace)
      : machines.filter((machine) => _places.indexOf(machine.place.uuid) > -1)
    const _types = _machines.map((machine) => machine.type.uuid)
    return machineTypes.filter((machineFilter) => _types.indexOf(machineFilter.uuid) > -1)
  }

  const [machine, setMachine] = useState(null)
  const handleMachineChange = (e) => setMachine(e.target.value || "")
  const tMachine = (machine) => machine ? formatMachineName(machine) : <span>&nbsp;</span>

  const getMachineOptions = () => {
    let _machines = [...machines]

    if (MFOperation) {
      _machines = _machines.filter((machine) => machine.place.operation.uuid === MFOperation)
    }

    if (MFPlace) {
      _machines = _machines.filter((machine) => machine.place.uuid === MFPlace)
    }

    if (MFType) {
      _machines = _machines.filter((machine) => machine.type.uuid === MFType)
    }

    return _machines
  }
  // --- machine ---

  const [operation, setOperation] = useState(userDetail.operation ? userDetail.operation.uuid : "")
  const handleOperationChange = (e) => {
    setOperation(e.target.value)
    setResponsibleUser(null)
    setTeam(null)
  }

  const users = useParentUsers(userDetail, operation)
  const [responsibleUser, setResponsibleUser] = useState(null)
  const handleResponsibleUserChange = (e) => setResponsibleUser(e.target.value)
  const userTemplate = (user) => <div>{user.name} ({user.operation.name}, {user.team.name})</div>
  const getUserOptions = () => team
    ? users.filter((user) => user.team.uuid === team)
    : users

  const { data: teams = [] } = useTeams()
  const [team, setTeam] = useState("")
  const handleTeamChange = (e) => {
    setTeam(e.target.value)
    setResponsibleUser(null)
  }
  const getTeamOptions = () => {
    // users are already filtered by operations
    const _teams = users.map((user) => user.team.uuid)
    return teams.filter((team) => _teams.indexOf(team.uuid) > -1)
  }

  const { data: taskTagOptions = [] } = useTaskTags()
  const [tag, setTag] = useState("")
  const handleTagChange = (e) => setTag(e.target.value)

  const [note, setNote] = useState("")
  const handleNoteChange = (e) => setNote(e.target.value)

  const [order, setOrder] = useState("")
  const handleOrderChange = (e) => setOrder(e.target.value)

  const [files, setFiles] = useState([])
  const handleFilesChange = (changedFiles) => setFiles(changedFiles)

  const [refresher, setRefresher] = useState(false)
  const refresh = () => setRefresher(!refresher)

  const [showAdditionalInfo, setShowAdditionalInfo] = useState(!hasRole("USER"))
  const toggleAdditionalInfo = () => setShowAdditionalInfo(!showAdditionalInfo)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingNew, setIsLoadingNew] = useState(false)

  const [createdBy, setCreatedBy] = useState({})

  const [task, setTask] = useState({})

  // nekvalita
  const [additionalOrder, setAdditionalOrder] = useState("")
  const onAdditionalOrderChange = (event) => setAdditionalOrder(event.target.value)

  const [additionalCommodity, setAdditionalCommodity] = useState("")
  const onAdditionalCommodityChange = (event) => setAdditionalCommodity(event.target.value)

  const [additionalAlloy, setAdditionalAlloy] = useState("")
  const onAdditionalAlloyChange = (event) => setAdditionalAlloy(event.target.value)

  const { data: taskDefects = [] } = useTaskDefects()
  const [additionalTaskDefect, setAdditionalTaskDefect] = useState("")
  const onAdditionalTaskDefectChange = (event) => setAdditionalTaskDefect(event.value)

  const [additionalAmount, setAdditionalAmount] = useState(0)
  const onAdditionalAmountChange = (event) => setAdditionalAmount(event.value)

  const { data: taskUnits = [] } = useTaskUnits()
  const [additionalTaskUnit, setAdditionalTaskUnit] = useState("")
  const onAdditionalTaskUnitChange = (event) => setAdditionalTaskUnit(event.value)

  const { data: taskProcesses = [] } = useTaskProcesses()
  const [additionalTaskProcess, setAdditionalTaskProcess] = useState("")
  const onAdditionalTaskProcessChange = (event) => setAdditionalTaskProcess(event.value || "")

  const [additionalProcessDescription, setAdditionalProcessDescription] = useState("")
  const onAdditionalProcessDescriptionChange = (event) => setAdditionalProcessDescription(event.target.value)

  const getAdditionalPayload = () => {
    let payload = {
      task_defect: additionalTaskDefect,
      task_process: additionalTaskProcess || null,
      // task_amount: "",
      task_unit: additionalTaskUnit,
      amount: additionalAmount,
      order: additionalOrder,
      commodity: additionalCommodity,
      alloy: additionalAlloy,
      process_description: additionalProcessDescription,
      // process_date: ""
      // processed_by: "",
      // outcome: "",
      // released: 0,
      // waste: 0
    }

    if (!uuid) {
      payload.process_date = moment().format("YYYY-MM-DD HH:mm:ss")
      payload.processed_by = userDetail.uuid
    }

    return payload
  }

  const create = (subUrl = "", saveFiles = true) => {
    if (saveFiles) {
      setIsLoading(true)
    } else {
      setIsLoadingNew(true)
    }

    const payload = {
      type: "REPORT", name, description, machine, tag, order,
      starts_at: moment(startsAt).seconds(0).toISOString(),
      ends_at: moment(endsAt).seconds(0).toISOString()
    }

    if (operation) {
      payload.operation = operation
    }

    if (team) {
      payload.team = team
    }

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    if (saveFiles && files.length > 0 && !managementUuid && !parentUuid) {
      payload.files = files.map((file) => {
        const newfile = Object.assign({}, file)
        delete newfile.uuid
        return newfile
      })
    }

    if (managementUuid && files.length > 0) {
      payload.files = files
    }

    if (managementUuid) {
      payload.from_management = managementUuid
    }

    if (parentUuid && files.length > 0) {
      payload.files = files
    }

    if (currentTagKey() === "nekvalita") {
      payload.additional = getAdditionalPayload()
    }

    api.put("/tasks", payload)
      .then(async ({ data }) => {
        const newReportUuid = data.data.uuid

        if (parentUuid) {
          await api.post(`/tasks/${parentUuid}/related`, {
            uuid: newReportUuid,
            is_child: true
          })
        }

        if (subUrl) {
          nav.push(`/task/report/${newReportUuid}/finish${subUrl}`)
        } else {
          nav.push(`/task/report/${newReportUuid}/detail`)
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsLoadingNew(false)
      })
  }

  const update = () => {
    setIsLoading(true)

    const payload = {
      uuid, type: "REPORT", state, name, description, machine, tag, order, starts_at: moment(startsAt).toISOString(), ends_at: moment(endsAt).toISOString()
    }

    if (operation) {
      payload.operation = operation
    }

    if (team) {
      payload.team = team
    }

    if (responsibleUser) {
      payload.user = responsibleUser
    }

    if (note) {
      payload.note = note
    }

    if (isAuthor()) {
      if (
        payload.name !== task.name ||
        payload.description !== task.description ||
        payload.machine !== task.machine.uuid
      ) {
        delete payload.state // keep original state
      }
    }

    if (currentTagKey() === "nekvalita") {
      payload.additional = getAdditionalPayload()
    }

    api.post(`/tasks/${uuid}`, payload)
      .then(() => nav.push(`/task/report/${uuid}/detail`))
      .finally(() => setIsLoading(false))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    uuid ? update() : create()
  }

  const handleFormSubmitNew = () => {
    create("", false)
  }

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)

          setState(data.data.state)
          setName(data.data.name)
          setDescription(data.data.description)
          setMachine(data.data.machine.uuid)
          setOrder(data.data.order || "")

          if (data.data.tag) {
            setTag(data.data.tag.uuid)
          }

          if (data.data.starts_at) {
            setStartsAt(moment(data.data.starts_at).toDate())
          }

          if (data.data.ends_at) {
            setEndsAt(moment(data.data.ends_at).toDate())
          }

          if (data.data.operation) {
            setOperation(data.data.operation.uuid)
          }

          if (data.data.team) {
            setTeam(data.data.team.uuid)
          }

          if (data.data.user) {
            setResponsibleUser(data.data.user.uuid)
          }

          setFiles(data.data.files || [])

          setCreatedBy(data.data.created_by)

          // nekvalita
          const additional = data.data.additional

          setAdditionalTaskDefect(additional.task_defect ? additional.task_defect.uuid : "")
          setAdditionalTaskProcess(additional.task_process ? data.data.additional.task_process.uuid : "")
          setAdditionalTaskUnit(additional.task_unit ? additional.task_unit.uuid : "")
          setAdditionalAmount(additional.amount || "")
          setAdditionalOrder(additional.order || "")
          setAdditionalCommodity(additional.commodity || "")
          setAdditionalAlloy(additional.alloy || "")
          setAdditionalProcessDescription(additional.process_description || "")
        })
    }
  }, [uuid, refresher])

  useEffect(() => {
    if (managementUuid) {
      api.get(`/task-managements/${managementUuid}`)
        .then(({ data }) => {
          const management = data.data

          setName(management.name || "")
          setDescription(management.description || "")

          if (management.machine) {
            if (management.machine.place && management.machine.place.operation) {
              setMFOperation(management.machine.place.operation.uuid)
            }
            setMachine(management.machine.uuid)
          }

          if (management.tag) {
            setTag(management.tag.uuid)
          }

          if (management.operation) {
            setOperation(management.operation.uuid)
          }

          if (management.starts_at) {
            const mStartsAt = moment(management.starts_at)
            setStartsAt(mStartsAt.toDate())
          }

          if (management.ends_at) {
            const mEndsAt = moment(management.ends_at)
            setEndsAt(mEndsAt.toDate())
          }

          if (management.team) {
            setTeam(management.team.uuid)
          }

          if (management.user) {
            setResponsibleUser(management.user.uuid)
          }

          if (management.order) {
            setOrder(management.order)
          }

          if (management.files) {
            setFiles(management.files)
          }
        })
    }
  }, [managementUuid])

  useEffect(() => {
    if (parentUuid) {
      api.get(`/tasks/${parentUuid}`)
        .then(({ data }) => {
          setTask(data.data)

          setState(data.data.state)
          setName(data.data.name)
          setDescription(data.data.description)
          setMachine(data.data.machine.uuid)
          setOrder(data.data.order || "")

          if (data.data.tag) {
            setTag(data.data.tag.uuid)
          }

          if (data.data.starts_at) {
            setStartsAt(moment(data.data.starts_at).toDate())
          }

          if (data.data.ends_at) {
            setEndsAt(moment(data.data.ends_at).toDate())
          }

          if (data.data.operation) {
            setOperation(data.data.operation.uuid)
          }

          if (data.data.team) {
            setTeam(data.data.team.uuid)
          }

          if (data.data.user) {
            setResponsibleUser(data.data.user.uuid)
          }

          setFiles(data.data.files || [])

          setCreatedBy(data.data.created_by)
        })
    }
  }, [parentUuid])

  const onFinishClick = () => {
    if (uuid) {
      nav.push(`/task/report/${uuid}/finish/1`)
    } else {
      create("/1")
    }
  }

  const onNotFinishClick = () => {
    if (uuid) {
      nav.push(`/task/report/${uuid}/finish/0`)
    } else {
      create("/0")
    }
  }

  const onOpenClick = async () => {
    await api.post(`/tasks/${uuid}`, { uuid, state: "IN_PROGRESS" })
    window.history.back()
  }

  const onCloseClick = async () => {
    await api.post(`/tasks/${uuid}`, { uuid, state: "CLOSED" })
    window.history.back()
  }

  const isAuthor = () => createdBy.uuid === userDetail.uuid

  const currentTagKey = () => {
    const taskTag = taskTagOptions.find((taskTag) => taskTag.uuid === tag)

    if (taskTag) {
      return taskTag.key
    }

    return ""
  }

  const isFormFilled = currentTagKey() === "nekvalita"
    ? name && description && machine && tag && additionalOrder && additionalCommodity && additionalAlloy && additionalTaskDefect && additionalAmount && additionalTaskUnit
    : name && description && machine && tag

  const isFormFieldDisabled = () => {
    if (uuid) {
      return !isAuthor()
    }

    return false
  }

  return (
    <>
      <form className={"col-12 md:col-7"} onSubmit={handleFormSubmit}>
        <div className={"p-fluid"}>
          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Název:</label>
            <InputText required={true} value={name} onChange={handleNameChange} maxLength={30}
              disabled={isFormFieldDisabled()} />
            <small className={commonStyles.fieldHelp}>{30 - name.length} znaků zbývá.</small>
          </div>

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Detailní popis:</label>
            <InputTextarea required={true} value={description} rows={5}
              onChange={handleDescriptionChange} disabled={isFormFieldDisabled()} />
          </div>

          <Fieldset className={"p-0 mb-3"}>
            <div className={"formgrid grid"}>
              <div className={"field col pl-0"}>
                <label>Provoz:</label>
                <Dropdown options={getMFOperationOptions()} value={MFOperation}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFOperationChange} />
              </div>

              <div className={"field col"}>
                <label>Místo:</label>
                <Dropdown options={getMFPlaceOptions()} value={MFPlace}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFPlaceChange} />
              </div>

              <div className={"field col pr-0"}>
                <label>Typ stroje:</label>
                <Dropdown options={getMFTypeOptions()} value={MFType}
                  optionLabel={"name"} optionValue={"uuid"}
                  showClear={true} filter={true}
                  onChange={handleMFTypeChange} />
              </div>
            </div>

            <div className={"field"}>
              <label className={commonStyles.labelRequired}>Stroj:</label>
              <Dropdown required={true} value={machine} options={getMachineOptions()}
                optionValue={"uuid"} optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                filter={true} showClear={true} filterBy={"name"}
                onChange={handleMachineChange} disabled={isFormFieldDisabled()}
                itemTemplate={tMachine}
                valueTemplate={tMachine} />
            </div>
          </Fieldset>

          {currentTagKey() === "nekvalita" && (
            <Fieldset className={"p-0 mb-3"}>
              <div className={"formgrid grid"}>
                <div className="field col pl-0">
                  <label className={commonStyles.labelRequired}>Zakázka nebo tavba:</label>
                  <InputText required={true} value={additionalOrder} onChange={onAdditionalOrderChange} />
                </div>

                <div className="field col">
                  <label className={commonStyles.labelRequired}>Výrobek nebo průměr čepu:</label>
                  <InputText required={true} value={additionalCommodity} onChange={onAdditionalCommodityChange} />
                </div>

                <div className="field col pr-0">
                  <label className={commonStyles.labelRequired}>Slitina nebo nástroj:</label>
                  <InputText required={true} value={additionalAlloy} onChange={onAdditionalAlloyChange} />
                </div>
              </div>
            </Fieldset>
          )}

          <div className={"field"}>
            <label className={commonStyles.labelRequired}>Typ:</label>
            <Dropdown required={true} value={tag} options={taskTagOptions} optionValue={"uuid"}
              optionLabel={"name"} emptyMessage={"Žádné záznamy"}
              onChange={handleTagChange}
              disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))} />
          </div>


          {currentTagKey() === "nekvalita" && (
            <>
              <Fieldset className={"p-0 mb-3"}>
                <div className={"formgrid grid"}>
                  <div className="field col pl-0">
                    <label className={commonStyles.labelRequired}>Vada:</label>
                    <Dropdown options={taskDefects} required={true}
                      value={additionalTaskDefect} onChange={onAdditionalTaskDefectChange}
                      optionLabel="name" optionValue="uuid" />
                  </div>

                  <div className="field col">
                    <label className={commonStyles.labelRequired}>Množství:</label>
                    <InputNumber required={true} value={additionalAmount} onChange={onAdditionalAmountChange}
                      useGrouping={false} />
                  </div>

                  <div className="field col pr-0">
                    <label className={commonStyles.labelRequired}>Jednotka:</label>
                    <Dropdown options={taskUnits} required={true}
                      value={additionalTaskUnit} onChange={onAdditionalTaskUnitChange}
                      optionLabel="name" optionValue="uuid" />
                  </div>
                </div>
              </Fieldset>

              {!hasRole("USER") && (
                <Fieldset className={"p-0 mb-3"}>
                  <div className={"formgrid grid"}>
                    <div className="field col pl-0">
                      <label>Postup:</label>
                      <Dropdown options={taskProcesses}
                        value={additionalTaskProcess} onChange={onAdditionalTaskProcessChange}
                        optionLabel="name" optionValue="uuid" showClear={true} />
                    </div>

                    <div className="field col">
                      {/* empty */}
                    </div>

                    <div className="field col">
                      {/* empty */}
                    </div>
                  </div>

                  <div className="field">
                    <label>Detailní popis:</label>
                    <InputTextarea value={additionalProcessDescription}
                      onChange={onAdditionalProcessDescriptionChange} />
                  </div>
                </Fieldset>
              )}
            </>
          )}

          <div className={"field"}>
            <label>Zakázka:</label>
            <InputText value={order} onChange={handleOrderChange} />
          </div>

          <Divider>
            <Button type={"button"} label={"Nepovinné doplňující informace"}
              icon={showAdditionalInfo ? "pi pi-caret-down" : "pi pi-caret-up"}
              className={"p-button-text p-button-outlined"}
              onClick={toggleAdditionalInfo} />
          </Divider>

          {showAdditionalInfo && (
            <>
              <div className={"field"}>
                <label>Řeší provoz:</label>
                <Dropdown value={operation} options={operations}
                  optionValue={"uuid"} optionLabel={"name"}
                  emptyMessage={"Žádné záznamy"}
                  onChange={handleOperationChange}
                  disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))} />
              </div>

              <div className={"field"}>
                <label>Požadovaný termín od:</label>
                <Calendar value={startsAt} dateFormat={"dd. mm. yy"} showTime={true}
                  hourFormat={24} onChange={handleStartsAtChange}
                  disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}
                  hideOnDateTimeSelect={true} />
              </div>

              <div className={"field"}>
                <label>Požadovaný termín do:</label>
                <Calendar value={endsAt} dateFormat={"dd. mm. yy"} showTime={true}
                  hourFormat={24} onChange={handleEndsAtChange}
                  disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))}
                  hideOnDateTimeSelect={true} />
              </div>

              <div className={"field"}>
                <label>Odpovědný tým:</label>
                <Dropdown value={team} options={getTeamOptions()}
                  optionValue={"uuid"} optionLabel={"name"} emptyMessage={"Žádné záznamy"}
                  onChange={handleTeamChange}
                  disabled={uuid && !(isAdmin() || hasRole("SUPERVISOR"))} />
              </div>

              <div className={"field"}>
                <label>Odpovědná osoba:</label>
                <Dropdown value={responsibleUser} options={getUserOptions()}
                  optionValue={"uuid"} itemTemplate={userTemplate} optionLabel={"name"}
                  emptyMessage={"Žádné záznamy"}
                  onChange={handleResponsibleUserChange} />
              </div>

              {uuid && (
                <>
                  <Divider />
                  <div className={"field"}>
                    <label>Poznámka k provedeným změnám:</label>
                    <InputTextarea value={note} onChange={handleNoteChange}
                      placeholder={"Poznámka k provedeným změnám"} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </form>

      <div className={"col-12 md:col-5"}>
        {showAdditionalInfo && (
          <>
            <FileList files={files} onChange={uuid ? refresh : handleFilesChange}
              entityUrl={uuid ? `/tasks/${uuid}` : ""}
              isDisabled={!hasPerm("ROLE_PERM_REPORT_WRITE")} />
          </>
        )}
      </div>

      <div className={"col-12 lg:col-7"}>
        <div className={"lg:flex"}>
          <Button type={"button"} label={"Zpět"} className={"p-button-warning m-1"}
            onClick={uuid ? back : backConfirmation} />
          <Button label={"Nelze dokončit"} onClick={onNotFinishClick} className={"m-1"}
            disabled={!hasPerm("ROLE_PERM_REPORT_WRITE") || !isFormFilled || state === "FINISHED" || state === "CLOSED"} />
          <Button label={"Hotovo"} onClick={onFinishClick} className={"m-1"}
            disabled={!hasPerm("ROLE_PERM_REPORT_WRITE") || !isFormFilled || state === "FINISHED" || state === "CLOSED"} />
          {isAdmin() && (
            <Button label={"Otevřít"} onClick={onOpenClick} className={"m-1"}
              disabled={!isAdmin() || !uuid || state === "CLOSED"} />
          )}
          {isAdmin() && (
            <Button label={"Uzavřít"} onClick={onCloseClick} className={"m-1"}
              disabled={!isAdmin() || !uuid || state === "CLOSED"} />
          )}
          <div className={"ml-auto"}>
            <Button type={"submit"}
              label={"Uložit"}
              className={"m-1"}
              icon={isLoading ? "pi pi-spin pi-spinner" : ""}
              onClick={handleFormSubmit}
              disabled={isLoading || isLoadingNew || !isFormFilled || !hasPerm("ROLE_PERM_REPORT_WRITE") || state === "FINISHED"}
              tooltip={"Uloží provedené změny."}
              tooltipOptions={{ position: 'top' }}
            />

            {uuid && (
              <Button type={"button"}
                label={"Uložit nové"}
                className={"m-1"}
                icon={isLoadingNew ? "pi pi-spin pi-spinner" : ""}
                disabled={isLoading || isLoadingNew || !isFormFilled || !hasPerm("ROLE_PERM_REPORT_WRITE")}
                onClick={handleFormSubmitNew}
                tooltip={"Původní hlášení zůstane beze změny a vytvoří se nové."}
                tooltipOptions={{ position: 'top' }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
