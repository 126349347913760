const getRevisionSubTaskStateName = (state) => {
  if (state === "FINISHED") {
    return "ANO"
  }

  if (state === "NOT_FINISHED") {
    return "NE"
  }

  if (state === "PARTIALY_FINISHED") {
    return "částečně"
  }

  return "otevřeno"
}

export default getRevisionSubTaskStateName
