import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import api from "../../../api"
import TaskPreventionRevisionSubTaskForm from "./TaskPreventionRevisionSubTaskForm"
import TaskPreventionSubTaskForm from "./TaskPreventionSubTaskForm"

export default function TaskPreventionSubForm() {
  const { uuid } = useParams()
  const [task, setTask] = useState()

  useEffect(() => {
    if (uuid) {
      api.get(`/tasks/${uuid}`)
        .then(({ data }) => {
          setTask(data.data)
        })
    }
  }, [uuid])

  if (!task) return ""

  return task.repeated === "YEARLY_PAR378"
    ? <TaskPreventionRevisionSubTaskForm />
    : <TaskPreventionSubTaskForm />
}
