export const minimalRequirementsSubtaks = [
  {
    description: "a) používání zařízení k účelům, pro které je určeno",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 1,
    files: [],
  },
  {
    description: "b) bezpečný přístup a dostatečný manipulační prostor",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 2,
    files: [],
  },
  {
    description: "c) přivádění a odvádění všech forem energií bezpečným způsobem",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 3,
    files: [],
  },
  {
    description: "d) vybavení zábranou nebo ochranným zařízením",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 4,
    files: [],
  },
  {
    description: "e) montování a demontování zařízení za bezpečných podmínek",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 5,
    files: [],
  },
  {
    description: "f) ochrana zaměstnance proti nebezpečnému dotyku u zařízení pod napětím",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 6,
    files: [],
  },
  {
    description: "g) ochrana zařízení před účinky atmosférické elektřiny",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 7,
    files: [],
  },
  {
    description: "h) umístění ovládacích prvků ovlivňujících bezpečnost",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 8,
    files: [],
  },
  {
    description: "i) spouštění zařízení obsluhou pomocí ovládače",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 9,
    files: [],
  },
  {
    description: "j) vybavení ovládačem pro úplné bezpečné zastavení",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 10,
    files: [],
  },
  {
    description: "k) vybavení ovládačem pro nouzové zastavení",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 11,
    files: [],
  },
  {
    description: "l) vybavení zařízením pro odpojení od všech zdrojů energií",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 12,
    files: [],
  },
  {
    description: "m) vybavení pracoviště ovládači k zastavení",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 13,
    files: [],
  },
  {
    description: "n) upevnění, ukotvení nebo zajištění zařízení nebo jeho části",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 14,
    files: [],
  },
  {
    description: "o) neohrožování zaměstnance např. hlukem, vibracemi, teplotami atd.",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 15,
    files: [],
  },
  {
    description: "p) označení výstražnými nebo informačními značkami, sděleními, atd.",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 16,
    files: [],
  },
  {
    description: "q) ochranné zařízení a zabezpečení proti ohrožení života a zdraví před padajícími, odlétajícími nebo vymrštěnými předměty",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 17,
    files: [],
  },
  {
    description: "r) ochranné zařízení a zabezpečení proti ohrožení života a zdraví před rizikem požáru nebo výbuchu",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 18,
    files: [],
  },
  {
    description: "s) ochranné zařízení a zabezpečení proti ohrožení života a zdraví před nebezpečným únikem plynných, kapalných a tuhých látek",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 19,
    files: [],
  },
  {
    description: "t) ochranné zařízení a zabezpečení proti ohrožení života a zdraví před zraněním způsobeným zachycením pohyblivou částí zařízení",
    value_description: "Způsob ověření",
    value_unit: "vyberte",
    value_min: null,
    value_max: null,
    position: 20,
    files: [],
  },
]
